import { connect } from 'react-redux';

import { AppState } from 'reducers';
import View from './view';
import { getEmail } from 'selectors';
import Session from '@getpopsure/session';
import { submitQuestionnaire } from 'actions';
import {
  getAsyncOperationErrorMessage,
  getAsyncOperationInProgress,
} from 'selectors/asyncOperation';

interface DispatchProps {
  onReloadQuestionnaire: () => void;
}

interface StateProps {
  questionnaireId?: string;
  downloading: boolean;
  email?: string;
  fullName: string;
  authenticated: boolean;
  reloadingQnr: boolean;
  reloadingQnrError?: string;
}

const getFullName = (state: AppState) => {
  if (state.questionnaire.personalInfo?.name) {
    return `${state.questionnaire.personalInfo.name.firstName} ${state.questionnaire.personalInfo.name.lastName}`;
  }
  return `${state.remoteData.user?.firstName} ${state.remoteData.user?.lastName}`;
};

export type ViewProps = StateProps & DispatchProps;

export const mapStateToProps = (state: AppState): StateProps => {
  const authenticated = Session.isAuthenticated;
  const email = getEmail(state);
  const fullName = getFullName(state);

  return {
    fullName,
    email,
    questionnaireId: state.remoteData.questionnaire?.id,
    downloading: false,
    authenticated,
    reloadingQnr: getAsyncOperationInProgress(state, 'post.questionnaire'),
    reloadingQnrError: getAsyncOperationErrorMessage(
      state,
      'post.questionnaire'
    ),
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    onReloadQuestionnaire: () => dispatch(submitQuestionnaire()),
  };
};

export default connect<StateProps, DispatchProps, unknown, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(View);
